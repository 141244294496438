/**
 * 멤버 서비스
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import Http from '../modules/Http';

/**
 * 멤버 정보 로드
 */
export const GetMember = async (): Promise<any> => {
  try {
    const result: any = await Http.get(`${process.env.REACT_APP_API_PATH}/member`);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 튜토리얼 실행 여부 업데이트
 */
export const UpdateTutorialStatus = async (): Promise<any> => {
  try {
    const result: any = await Http.put(`${process.env.REACT_APP_API_PATH}/member`, {
      tutorial: 'true',
    });
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 멤버 정보 업데이트
 */
export const UpdateMember = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.put(`${process.env.REACT_APP_API_PATH}/member`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 회원 비밀번호 업데이트
 */
export const UpdateMemberPassword = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.put(`${process.env.REACT_APP_API_PATH}/member/change/password`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 회원 계정 찾기
 */
export const FindMember = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/member/find`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 회원 비밀번호 재설정 코드 전송
 */
export const sendPasswordCode = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/member/send/code`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 발급받은 코드 체크
 */
export const checkPasswordCode = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/member/check/code`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 코드를 포함한 패스워드 변경
 */
export const resetPasswordCode = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/member/reset/password`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};

/**
 * 이중접속 방지 토글 변경
 */
export const UpdateConcurrentStatus = async (concurrent: boolean): Promise<any> => {
  try {
    const result: any = await Http.put(`${process.env.REACT_APP_API_PATH}/member/concurrent`, { concurrent });
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (err) {
    console.log('Err, services/MemberService.ts', err);
    return Promise.reject(err);
  }
};
