//action's name
const LOAD_BOT_SETTINGS = 'LOAD_BOT_SETTINGS';
const SET_BOT_USE_CHANNEL = 'SET_BOT_USE_CHANNEL';
const SET_BOT_PARAMS = 'SET_BOT_PARAMS';
const GET_SETTING_BOT = 'GET_SETTING_BOT';
const SET_BOT_NAME = 'SET_BOT_NAME';
const SET_BOT_DESC = 'SET_BOT_DESC';
const SET_AUTO_UPLOAD = 'SET_AUTO_UPLOAD';
const SET_THUMBNAIL = 'SET_THUMBNAIL';
const SET_CUSTOM_OPTION = 'SET_CUSTOM_OPTION';

//initialstate types
type Channel = {
  _id: string;
  botId: string;
  target: string;
  use: boolean;
  isFriend?: boolean;
  title?: string;
  message?: string;
  img?: string;
  profileId?: string;
};

type Param = {
  _id: string;
  paramName: string;
  paramEnt: {
    value: string;
    label: string;
  };
  paramValue: string;
};

type Config = {
  channels: Channel[];
  parameters: Param[];
  activeTime: any;
  counselorButtonTime: any;
};

type CustomOptions = {
  auth: any;
  AI?: any;
  kakaosync?: any;
  endTalkInfo?: any;
  enc?: any;
};

export interface BotType {
  _id: string;
  activeTime: any;
  counselorButtonTime: any;
  config: Config;
  createdAt: string;
  memberId: string;
  name: string;
  desc: string;
  state: string;
  updatedAt: string;
  use: boolean;
  useCounselor: boolean;
  autoUpload: boolean;
  thumbnail: string;
  customOptions?: CustomOptions;
}

interface initialTypes {
  config?: Config;
  target: BotType | null;
}

//setting initialstate
const initialState: initialTypes = {
  config: undefined,
  target: null,
};

//action
export const loadSettingInfo = (config: Config) => {
  return {
    type: LOAD_BOT_SETTINGS,
    data: config,
  } as const;
};

export const updateChannel = (channels: Channel[]) => {
  return {
    type: SET_BOT_USE_CHANNEL,
    data: channels,
  } as const;
};

//////////////// new version
export const getSettingInfo = (target: BotType) =>
  ({
    type: GET_SETTING_BOT,
    data: target,
  } as const);

export const setBotName = (target: BotType) =>
  ({
    type: SET_BOT_NAME,
    data: target,
  } as const);

export const setBotDesc = (target: BotType) =>
  ({
    type: SET_BOT_DESC,
    data: target,
  } as const);

export const setParam = (target: BotType) =>
  ({
    type: SET_BOT_PARAMS,
    data: target,
  } as const);

export const setAutoUpload = (target: BotType) =>
  ({
    type: SET_AUTO_UPLOAD,
    data: target,
  } as const);

export const setThumbnail = (target: BotType) =>
  ({
    type: SET_THUMBNAIL,
    data: target,
  } as const);

export const setCustomOptions = (options: any) =>
  ({
    type: SET_CUSTOM_OPTION,
    data: options,
  } as const);

//action types
type SettingActionType =
  | ReturnType<typeof loadSettingInfo>
  | ReturnType<typeof updateChannel>
  | ReturnType<typeof setParam>
  | ReturnType<typeof getSettingInfo>
  | ReturnType<typeof setBotName>
  | ReturnType<typeof setBotDesc>
  | ReturnType<typeof setAutoUpload>
  | ReturnType<typeof setThumbnail>
  | ReturnType<typeof setCustomOptions>;
const settingReducer = (state: initialTypes = initialState, action: SettingActionType): initialTypes => {
  switch (action.type) {
    case LOAD_BOT_SETTINGS: {
      return {
        ...state,
        config: action.data,
      };
    }
    case SET_BOT_USE_CHANNEL: {
      const target = state.target as BotType;

      target.config.channels = action.data;
      return {
        ...state,
        target,
      };
    }
    case SET_BOT_PARAMS: {
      const target = { ...(state.target as BotType) };
      (target as BotType).config.parameters = action.data.config.parameters;
      return {
        ...state,
        target,
      };
    }
    case GET_SETTING_BOT: {
      return {
        ...state,
        target: action.data,
      };
    }
    case SET_BOT_NAME: {
      // const target = state.target as BotType;
      const target = { ...(state.target as BotType) };
      target.name = action.data.name;
      target.desc = action.data.desc;
      return {
        ...state,
        target,
      };
    }
    case SET_BOT_DESC: {
      const target = { ...(state.target as BotType) };
      target.desc = action.data.desc;
      return {
        ...state,
        target,
      };
    }
    case SET_AUTO_UPLOAD: {
      const target = { ...(state.target as BotType) };
      target.autoUpload = action.data.autoUpload;
      return {
        ...state,
        target,
      };
    }
    case SET_THUMBNAIL: {
      const target = { ...(state.target as BotType) };
      target.thumbnail = action.data.thumbnail;
      return {
        ...state,
        target,
      };
    }
    case SET_CUSTOM_OPTION: {
      // const target = state.target as BotType;
      const target = { ...(state.target as BotType) };
      target.customOptions = action.data;
      return {
        ...state,
        target,
      };
    }
    default: {
      return state;
    }
  }
};

export default settingReducer;
